import React, { Suspense } from 'react';
import {Route, Switch, withRouter, Redirect} from 'react-router-dom';

import Layout from './layout/layout';

const Index = React.lazy(() => {
    return import('./pages/index');
});
const Journey = React.lazy(() => {
    return import('./pages/journey');
});
const Projects = React.lazy(() => {
    return import('./pages/projects');
});
const Learning = React.lazy(() => {
    return import('./pages/learning');
});

const App = () => {

    let routes = (
        <Switch>
            <Route exact path="/" component={Index} />
            <Route path="/journey" component={Journey} />
            <Route path="/projects" component={Projects} />
            <Route path="/learning" component={Learning} />
            <Redirect to="/" />
        </Switch>
    );

    return (
        <div>
            <Layout>
                <Suspense fallback={<p>Loading...</p>}>{routes}</Suspense>
            </Layout>
        </div>
    );
}

export default withRouter(App);