import React from 'react';
import NavigationItem from './navigationItem/navigationItem';

const navigationItems = (props) => (

    <nav className='navigation_nav'>
        <ul className='navigation_list'>
             <NavigationItem link='/' toggleShowNavigation={props.toggleShowNavigation}>HOME</NavigationItem>
            <NavigationItem link='/journey' toggleShowNavigation={props.toggleShowNavigation}>JOURNEY</NavigationItem>
            <NavigationItem link='/projects' toggleShowNavigation={props.toggleShowNavigation}>PROJECTS</NavigationItem>
            <NavigationItem link='/learning' toggleShowNavigation={props.toggleShowNavigation}>LEARNING</NavigationItem>
        </ul>
    </nav>
);

export default navigationItems;