import React from 'react';

import Navigation from '../components/navigation/navigation';
import Footer from '../components/footer';

const layout = (props) => (

    <React.Fragment>

        <Navigation />
        
        <main className='layout'>
            {props.children}
        </main>
        <Footer />
    </React.Fragment>
);

export default layout;