import React, {useState} from 'react';
import NavigationItems from './navigationItems/navigationItems';

const Navigation = (props) => {

    const [showNavigation, setShowNavigation] = useState(false);

    const toggleShowNavigation = () => setShowNavigation(!showNavigation);

    return (
        <div className='navigation'>
            <input
                type="checkbox"
                className='navigation_checkbox'
                id='navigation-toggle'
                checked={showNavigation}
                onChange={toggleShowNavigation}
            />
            <label htmlFor="navigation-toggle" className="navigation_button">
                <span className="navigation_icon">&nbsp;</span>
            </label>
            <div className="navigation_background">&nbsp;</div>
            
            <NavigationItems toggleShowNavigation={toggleShowNavigation}/>
        </div>
    );
}

export default Navigation;