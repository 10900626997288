import React from 'react';

const footer = () => (

    <footer className="footer">

        <svg xmlns="http://www.w3.org/2000/svg" style={{display: 'none'}}>
            <symbol id="twitter" viewBox="0 0 16 16">
                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/>
            </symbol>
            <symbol id="linkedin" viewBox="0 0 24 24">
                <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z"/>
            </symbol>
        </svg>

        <div className='container'>
            <div className="row">
                <div className="footer-text u-margin-bottom-small">
                    <h2 className="footer-quote">
                        "I live in the great city of Manchester and love to talk all aspects of software development"
                    </h2>
                    <p  className="footer-padding">A great way to get my attention is to follow me and engage with my posts on Twitter and LinkedIn.</p>
                </div>
                <div className="align-items-center">
                <ul className="nav justify-content-center list-unstyled">
                    <li className="ms-3"><a className="text-muted" href="https://www.twitter.com/MattCole1975" target="_blank" rel="noreferrer"><svg className="footer-bi" width="24" height="24"><use xlinkHref="#twitter"/></svg></a></li>
                    <li className="ms-3"><a className="text-muted" href="https://www.linkedin.com/in/mattcole75/" target="_blank" rel="noreferrer"><svg className="footer-bi" width="24" height="24"><use xlinkHref="#linkedin"/></svg></a></li>
                </ul>
            </div>
            </div>
        </div>
    </footer>
)

export default footer;